import styled from 'styled-components'

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
`
