import styled from 'styled-components'

export const StyledLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #logo {
    position: relative;
    background: #ffffff;
    border: 7px solid #f66d6d;
    color: #f66d6d;
    box-shadow: -2px -1px 0px #f66d6d, -4px -2px 0px #f66d6d,
      -6px -3px 0px #f66d6d, -8px -4px 0px #f66d6d, -10px -5px 0px #f66d6d,
      -12px -6px 0px #f66d6d;
    border-radius: 19px;
    transform: matrix(1, -0.19, 0, 0.98, 0, 0) scale(1.5, 2.25);
    padding: 16px 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    font-weight: bold;
    line-height: 0.5;
  }

  #commingSoon {
    position: absolute;
    bottom: 20vh;
    right: 7vw;
    font-size: 18px;
    color: #f66d6d;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }

  #brexit {
    color: #f66d6d;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 18vh;
    left: 7vw;
  }
`
