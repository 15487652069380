import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import { StyledFooter } from './styled'

const Footer = () => {
  return (
    <StyledFooter>
      <div id="socials">
        <a
          href="https://www.facebook.com/belgiquebelxit/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          href="https://twitter.com/belxit_be"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCAHeawYl-TYRXzMi4MAwLAQ/playlists"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      {/* <div id="copy">
        © Belxit_be {new Date().getFullYear()}
      </div> */}
    </StyledFooter>
  )
}

export default Footer
