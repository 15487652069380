import styled from 'styled-components'

export const StyledFooter = styled.footer`
  font-family: Arial, Helvetica, sans-serif;
  color: #f66d6d;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 98%;
  padding: 1.2rem 2.4rem;
  margin: 0 auto;
  border-top: 1px solid #f3f3f3;

  #socials {
    a {
      margin: 0 1.2rem;
      color: #f66d6d;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
      svg {
        font-size: 20px;
      }
    }
  }

  #copy {
    font-size: 14px;
    color: #f66d6d;
  }
`
