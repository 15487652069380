import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { StyledFlexContainer } from '../../common/StyledComponents/styled'
import { StyledLogoContainer } from './styled'
import Footer from '../Footer'
import '../layout.css'

dayjs.extend(relativeTime)

const UnderDevelopment = () => {


  return (
    <>
      <StyledFlexContainer>
        <StyledLogoContainer>
          <div id="logo">BELXIT></div>
          <div id="commingSoon">
            Site en construction... <span role="img">👷</span>
          </div>
        </StyledLogoContainer>
      </StyledFlexContainer>
      <Footer />
    </>
  )
}

export default UnderDevelopment
