import React from 'react'

import UnderDevelopment from '../components/UnderDevelopment'
// import Layout from '../components/Layout'
import SEO from '../components/Seo'

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <UnderDevelopment />
      {/* <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        /> */}
    </>
  )
}

export default IndexPage

// export const pageQuery = graphql`
//   query BlogPostByPath {
//     markdownRemark(frontmatter: { title: { eq: "Berlin s'insurge contre le doublement de sa participation au budget de l'UE" } }) {
//       html
//     }
//   }
// `
